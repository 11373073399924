<template>
  <pull-to-refresh :disabled="$vuetify.breakpoint.mdAndUp" @refresh="getUserLogs">
    <div v-if="loading && currentPaginationOffset === 0">
      <div v-for="(skeleton, i) in Array.from(Array(4).keys())" :key="i">
        <v-skeleton-loader type="text" min-width="100%" class="mb-2 mt-4" />
        <v-skeleton-loader type="list-item-two-line" min-width="100%" />
      </div>
    </div>
    <div v-else>
      <div v-for="(log, index) in logs" :key="index" class="user-log-container--wrap mb-4">
        <div class="datetime mb-4">
          {{ log.dateTime | formatDate('MMMM Do, YYYY [at] H:mm A', false) }}
        </div>
        <div class="user-log--container pa-4 d-flex align-center">
          <div class="d-flex align-center flex-grow-0">
            <v-badge
              :color="getIconBadgeColor(log.action)"
              overlap
              bottom
              :icon="getBadgeIcon(log.action)"
              :value="showBadge(log.action)"
            >
              <v-icon :color="showBadge(log.action) ? '' : getIconBadgeColor(log.action)">{{
                getIcon(log.action)
              }}</v-icon>
            </v-badge>
            <div
              class="user-log--action ml-4 ml-sm-8"
              :style="{ color: `var(--v-${getIconBadgeColor(log.action)}-base)` }"
            >
              {{ $t(`patientPortal.settings.logActions.${log.action}`).toUpperCase() }}
            </div>
          </div>
          <div
            v-if="
              !$vuetify.breakpoint.xsOnly &&
              (log.action === 'file-create' || log.action === 'file-update')
            "
            class="file-name d-flex align-center ml-16"
          >
            {{ getFileName(log.auditData) }}
          </div>
        </div>
      </div>
      <div class="full-width d-flex justify-center" v-if="initialized">
        <div v-if="endOfLogs">{{ $t('patientPortal.settings.end') }}</div>
        <v-btn v-else-if="logs && logs.length" text @click="getUserLogs" :loading="loading">{{
          $t('patientPortal.settings.viewMore')
        }}</v-btn>
        <div v-else-if="!logs || !logs.length">
          {{ $t('patientPortal.settings.none') }}
        </div>
      </div>
    </div>
  </pull-to-refresh>
</template>
<style lang="scss" scoped>
@import '../../styles/patient-portal.scss';
.user-log-container--wrap {
  .datetime {
    font-size: 0.8em;
  }
  .user-log--container {
    border-radius: 16px;
    box-shadow: $pretty-shadow;
    background-color: white;
  }
  .user-log--action {
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.1em;
  }
  .file-name {
    font-size: 0.8em;
  }
}
</style>
<script>
import GET_USER_LOGS from '../../graphql/Query/GetUserLogs.gql';

import PullToRefresh from './PullToRefresh.vue';

export default {
  data() {
    return {
      currentPaginationOffset: 0,
      endOfLogs: false,
      initialized: false,
      PAGINATION_SIZE: 5,
      loading: false,
      logs: [],
    };
  },
  components: {
    PullToRefresh,
  },
  methods: {
    getFileName(data) {
      try {
        const parsed = JSON.parse(data);
        const { fileName } = parsed;
        return fileName;
      } catch {
        return 'Unknown file';
      }
    },
    getBadgeIcon(action) {
      return {
        'login-attempt-successful': 'mdi-check',
        'login-attempt-failed': 'mdi-close',
      }[action];
    },
    getIcon(action) {
      return {
        'appointment-update': 'mdi-calendar-remove',
        'login-attempt-successful': 'mdi-login',
        'login-attempt-failed': 'mdi-login',
        'file-create': 'mdi-file-document-plus-outline',
        'file-update': 'mdi-file-document-edit-outline',
      }[action];
    },
    getIconBadgeColor(action) {
      return (
        {
          'appointment-update': 'error',
          'login-attempt-successful': 'success',
          'login-attempt-failed': 'error',
        }[action] || 'primary'
      );
    },

    async getUserLogs() {
      this.loading = true;
      try {
        const { id: userId } = this.$store.getters['patientPortal/currentUser'];
        const res = await this.$apollo.query({
          query: GET_USER_LOGS,
          variables: {
            providerId: this.$store.getters['patientPortal/providerId'],
            userRole: 'member',
            userId,
            limit: this.PAGINATION_SIZE,
            filter: {
              actionsRaw: [
                'login-attempt-failed',
                'login-attempt-successful',
                'appointment-update',
                'file-create',
                'file-update',
              ],
              resourcesRaw: [
                'loginAttemptEvents',
                'fileEvents',
                'appointmentEvents',
                'memberEvents',
              ],
            },
            offset: this.currentPaginationOffset,
          },
          fetchPolicy: 'no-cache',
        });

        const { getAll: logs } = res?.data?.AuditService?.UserAudit;
        this.logs = [...this.logs, ...(logs || [])];
        if (!logs.length) this.endOfLogs = true;
      } catch {
        this.$store.commit('setNotification', {
          color: 'error',
          showing: true,
          text: this.$t('patientPortal.settings.notification.error'),
          timeout: 5000,
        });
      }
      this.currentPaginationOffset += this.PAGINATION_SIZE;
      this.loading = false;
    },
    showBadge(action) {
      return ['login-attempt-successful', 'login-attempt-failed'].includes(action);
    },
  },
  async mounted() {
    await this.getUserLogs();
    this.initialized = true;
  },
};
</script>
